<template>
  <div class="help-detail">
    <helloWorld />
    <div class="helpTop">
      <h3> <span></span>隐私政策</h3>
    </div>
    <div class="helpMain">
      <div class="content">
        我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时、融汇科技承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        <br />
        请在使用我们的产品或服务前，仔细阅读并了解本《隐私政策》。
        <br />
        您知晓并确认您在注册时已经阅读本政策的所有内容，同意我们按照本政策来合法处理您的个人信息。为便于您阅读本政策，我们对所有可能与您的合法利益存在重大关系的条款以及涉及敏感信息内容都通过“加黑加粗”的书写方式进行了特别提醒。在向我们提供任何个人敏感信息前，请您结合本政策相关条款清楚考虑该等提供是否恰当并确认是否同意我们可按本政策所述的目的和方法处理您的个人敏感信息，我们会在得到您的同意后收集和使用您的敏感信息，以实现与融汇科技服务相关的功能。您可以拒绝向我们提供这些敏感信息，但是拒绝提供这些信息会影响您使用融汇科技服务项下的特定功能。如果您不同意或者不理解本政策的部分或全部内容，请立即停止使用我们的产品和/或服务。
        <br />
        本政策仅适用于融汇科技平台向您提供的所有服务（以下统称“融汇科技服务”）。
        <br />
        更新日期：XXXX年XX 月XX日
        <br />
        生效日期: XXXX年XX 月XX日
        <br />
        运营公司名称：亚联融汇（北京）科技有限公司
        <br />
        如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：
        <br />
        邮箱：data@afca-t.com
        <br />
        本政策将帮助您了解以下内容：
        <br />
        1.我们如何收集和使用您的个人信息
        <br />
        2.我们如何使用cookie和同类技术
        <br />
        3.我们如何共享、转让、公开披露您的个人信息
        <br />
        4.我们如何保护您的个人信息
        <br />
        5.您的权利
        <br />
        6.未成年人个人信息保护
        <br />
        7.我们如何存储个人信息
        <br />
        8.本政策如何更新
        <br />
        9.争议的解决
        <br />
        10.如何联系我们
        <br />

        <span style="font-weight:bold">一、我们如何收集和使用您的个人信息</span>
        <br />
        个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息。 我们只会在您使用特定业务功能时，仅收集为了正常运行该业务功能所必需的信息，在您停止该业务功能的使用后，我们将会停止您的个人信息收集的行为。请您理解，由于商业策略的变化，平台上的产品和服务的功能也可能进行更迭和发展。如果我们要将您的个人信息用于本政策未载明的其他用途，或基于特定目的将收集而来的个人信息用于其他目的的，我们会通过平台以合理的方式（如弹窗等形式）向您告知，并在使用前再次征得您的同意。 融汇科技仅会出于本政策所述的以下目的，收集和使用您的个人信息：
        <br />
        <span style="font-weight:bold">（一）注册、登录、认证</span>
        <br />
        当您注册成为融汇科技平台用户时，您需要提供您的真实身份信息（真实姓名、身份证号码）和人脸面部特征、银行卡账号以完成实名认证并完成注册。真实姓名、身份证号码和人脸面部特征属于个人敏感信息，您可以拒绝提供，如果拒绝提供你将可能无法获得相关服务，但不影响其他功能与服务的正常使用。 在您注册成为融汇科技平台用户时，您可选择提供您的邮箱，以便于保存您的私钥。 您注册成为融汇科技平台用户后，当您日后登录融汇科技平台时，您需要提供您的人脸面部特征以完成平台登录。 您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。
        <br />
        <span style="font-weight:bold">（二）向您展示商品或服务信息</span>
        <br />
        当我们向您展示商品或服务信息，我们会收集和使用融汇科技平台网站或客户端时的浏览、搜索记录及设备信息、服务日志信息，以及其他取得您授权的信息。其中： 设备信息是您在访问及/或使用我们服务过程中，我们接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID等设备标识符、设备环境、移动应用列表等软硬件特征信息）、设备所在位置相关信息（包括通过GPS信息、WLAN接入点、蓝牙和基站等传感器信息获取到的精确地理位置信息及通过网络位置信息（例如基站、IP及WLAN）获取的大致地理位置信息。精确地理位置信息属于个人敏感信息，如您关闭位置权限，我们将不会获取您的精确位置信息，且不会影响融汇科技其他功能的正常使用）。 服务日志信息是您在访问及/或使用我们服务过程中，我们自动收集和记录您的详细使用情况，包括浏览记录、点击查看记录、搜索查询记录、收藏、交易、发布信息、IP地址、浏览器类型、访问日期和时间。 请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
        <br />
        <span style="font-weight:bold">（三）帮助您完成下单及订单管理</span>
        <br />
        当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需至少提供您的收货人姓名、收货地址、收货人联系电话。同时该订单中会载明您所购买的商品及/或服务信息、具体订单号、订单创建时间、您应支付的金额、您的备注信息。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、便于您查询订单信息、提供客服与售后服务及其他我们明确告知的目的。 您可以通过融汇科技平台为其他人订购商品及/或服务，您需要提供该实际收货人的前述个人信息，并确保已取得该实际收货人的同意。 为便于您了解、查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。
        <br />
        <span style="font-weight:bold">（四）帮助您完成支付</span>
        <br />
        为完成订单支付，我们为您提供微信付款方式，您需要选择支付并授权同意我们将订单支付相关信息及其他反洗钱法律要求的必要信息与您选择的第三方支付机构共享。 为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您授权同意我们可自您所选择的第三方支付机构收集与支付进度相关信息。
        <br />
        <span style="font-weight:bold">（五）帮助向您完成商品或服务的交付</span>
        <br />
        根据您与商品及/或服务提供主体的约定，相关配送服务主体（包括商家或第三方配送服务提供商）将为您完成订单的交付，您知悉并同意我们有权将您的订单信息提供给商品/服务供应商、物流服务商，以保证您购买的商品及/或服务能够顺利、安全、准确送达。 为向您提供售后与争议解决服务之目的，我们需要及时获悉并确认交付进度及状态，您同意我们可自物流相关服务主体处收集与交付进度相关的信息。
        <br />
        <span style="font-weight:bold">（六）客服及争议处理</span>
        <br />
        当您与我们联系或提出售中、售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的身份。  
        <br />
        <span style="font-weight:bold">（七）为您提供安全保障</span>
        <br />
        为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或融汇科技平台相关协议规则的情况，我们会使用或整合您的账户信息、订单信息、设备信息、日志信息、地理位置信息、软件列表、软件使用环境以及合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、欺诈检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施，确保网络及信息安全。
        <br /> 
        <span style="font-weight:bold">二、我们如何使用cookie和同类技术</span>
        <br />
        <span style="font-weight:bold">（一）Cookie</span>
        <br />
        为使您获得更轻松的使用体验，您使用融汇科技服务时，我们可能会通过cookie识别到您的身份（Cookie通常包含标识符、站点名称以及一些号码和字符），这么做可帮您省去重复登录的步骤。我们不会将cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时，亲自更改用户设置。
        <br />
        <span style="font-weight:bold">（二）网站信标和像素标签</span>
        <br />
        除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望我们的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        <br />
        <span style="font-weight:bold">（三）Do Not Track（请勿追踪）</span>
        <br />
        很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track请求。目前我们互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，我们的所有网站都会尊重您的选择。 如您不同意上述第三方服务商收集前述信息，可能无法获取相应服务，但不影响您正常使用融汇科技APP的其他功能或服务。如您同意上述相关第三方服务商收集前述信息，发生信息泄露的，由相关第三方服务商承担相应的法律责任。请您理解，当您使用融汇科技访问第三方创建的网页时，这些第三方可能通过自己的Cookie ，Web Beacome收集您的信息，该等行为不受我们控制基本政策的约束，而是受该第三方个人信息保护政策约束。我们建议您在使用第三方提供的服务前，仔细阅读该等第三方个人信息保护政策的内容，我们也会履行必要的审核义务，并努力去要求第三方对您的个人信息采取保护措施。
        <br />
        <span style="font-weight:bold">三、我们如何共享、转让、公开披露您的个人信息</span>
        <br />
        <span style="font-weight:bold">（一）共享</span>
        <br />
        我们不会与亚联融汇（北京）科技有限公司以外的任何公司、组织、个人分享您的个人信息，但以下情况除外：
        <br />
        1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。共享个人信息的范围、目的等，将在获取您的授权或同意时说明展示。
        <br />
        2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求共享您的个人信息。共享个人信息的范围，将根据法律法规及相关行政或司法部门要求而定。
        <br />
        3、与我们的关联公司共享您的个人信息。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们会要求与我们共享您的个人信息的关联公司，按照本政策约定的方式存储处理您的个人信息。在共享信息前，我们会进行商业上的合理审查评估，共享您个人信息的合法性、正当性、必要性，并督促相关方按照法律规定和监管要求来处理您的信息。我们的关联公司如要改变个人信息的处理目的，将在此征求您的授权，同意。
        <br />
        4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定明确的目的，共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于其他用途。
        <br />
        <span style="font-weight:bold">（二）转让</span>
        <br />
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        <br />
        1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        <br />
        2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会再要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        <br />
        <span style="font-weight:bold">（三）公开披露</span>
        <br />
        我们仅会在以下情况下，公开披露您的个人信息：
        <br />
        1、获得您明确同意后；
        <br />
        2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        <br />
        <span style="font-weight:bold">四、我们如何保护您的个人信息</span>
        <br />
        为了遵守法律法规与监管要求，同时为了便于您查询相关的个人信息，我们将保存您的申请或使用融汇科技相关服务过程中提供产生的必要信息。我们对相关信息通过专业技术手段采取加密存储与传输，保障您个人信息的安全。
        <br />
        （一）我们努力采取合理的安全防护措施保护您的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。我们同时对于融汇科技有合作的公司提供的链接，提供HTTPS安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识；超出必要期限后，我们将会对您的个人信息进行删除和或匿名化处理，但法律法规另有规定除外。
        <br />
        （二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        <br />
        （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        <br />
        （四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护措施设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        <br />
        （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取将要采取的处理措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时我们还将按照监管部门要求，主动上报个人信息安全事件的处理情况。
        <br />
        <span style="font-weight:bold">五、您的权利</span>
        <br />
        按照中国相关的法律、法规、标准，以及其他国家地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        <br />
        <span style="font-weight:bold">（一）访问您的个人信息</span>
        <br />
        您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问；
        <br />
        账户信息——如果您希望访问或编辑您的账户中的个人资料信息，支付信息、您可以通过融汇科技APP执行此类操作。
        <br />
        对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。
        <br />
        <span style="font-weight:bold">（二）更正您的个人信息</span>
        <br />
        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。
        <br />
        <span style="font-weight:bold">（三）删除您的个人信息</span>
        <br />
        在以下情形中，您可以向我们提出删除个人信息的请求：
        <br />
        1、如果我们处理个人信息的行为违反法律法规；
        <br />
        2、如果我们收集、使用您的个人信息，却未征得您的同意；
        <br />
        3、如果我们处理个人信息的行为违反了与您的约定；
        <br />
        4、如果您不再使用我们的产品或服务，或您注销了账号；
        <br />
        5、如果我们不再为您提供产品或服务。
        <br />
        当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        <br />
        <span style="font-weight:bold">（四）改变您授权同意的范围</span>
        <br />
        每个业务功能需要一些基本的个人信息才能得以完成。（见本政策“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权。
        您可以通过以下方式操作，发送电子邮件至bd@hzmilian.cn。 当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        <br />
        <span style="font-weight:bold">（五）注销账户</span>
        <br />
        您可以注销此前注册的账户，您知悉并理解，注销账号的行为是不可逆的行为，在注销账户之后，我们将停止为您提供产品或服务，删除您的个人信息或进行匿名化处理，但法律法规另有规定的除外。
        <br />
        <span style="font-weight:bold">（六）约束信息系统自动决策</span>
        <br />
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
        <br />
        <span style="font-weight:bold">（七）响应您的上述请求</span>
        <br />
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 我们将在15个工作日内做出答复。您可以通过以下方式投诉：发送电子邮件至bd@hzmilian.cn。 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。 在以下情形中，按照法律法规的要求，我们将无法响应您的请求：
        <br />
        1、与个人信息管控者履行法律法规规定的义务，相关的；
        <br />
        2、与国家安全、国防安全相直接相关的
        <br />
        3、与公共安全、公共卫生、重大公共利益直接相关的；
        <br />
        4、与犯罪侦查、起诉、审判和执行判决等直接相关的；
        <br />
        5、有充分证据表明您存在主观恶意或滥用权利的；
        <br />
        6、响应您的请求将导致您或其他个人。组织的合法权益受到严重损害的；
        <br />
        7、涉及商业秘密的。
        <br />

        <span style="font-weight:bold">六、未成年人的个人信息保护</span>
        <br />
        如果您是未满18周岁的未成年人，在使用融汇科技相关服务前，应在您的父母或监护人监护、指导下共同阅读并同意本隐私政策。 同时,我们承诺，我们将根据国家相关法律法规及本政策的规定，确保未成年人用户信息的保密性及安全性。如果我们发现在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，我们会采取必要措施，主动及时地删除相关信息。若您是未成年人的监护人，当您注意到您所监护的未成年人存在使用平台上的产品/服务，或自行向我们提供个人信息的情况，请您及时与我们联系。
        <br />
        <span style="font-weight:bold">七、我们如何存储个人信息</span>
        <br />
        我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。我们仅在为提供融汇科技服务之目的所必需的期间内保留您的个人信息，在您未撤回、删除或未注销账号期间，我们会保留相关信息。超出必要期限后，我们将会对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
        另外，如果我们的产品或服务发生了停止运营的情况时，我们将以推送通知、公告等形式告知您，并在合理的期限内删除您的个人信息或进行匿名化处理。
        <br />
        <span style="font-weight:bold">八、本政策如何更新</span>
        <br />
        为了给您提供更好的服务，融汇科技相关的服务将不时更新与变化，我们可能会适时对本政策进行更新修订。
        <br />
        未经您明确同意，我们不会削减您按照本隐私政策所享有的权利。如本隐私政策发生更新，我们会在融汇科技平台及时更新，并通过合理的方式（包括但不限于弹窗提示、系统公告、向您发送电子邮件/短消息等方式通知您，以便您能及时了解本政策任何变化。
        <br />
        如果您在本政策更新后继续使用融汇科技服务，则视为您已充分阅读并接受本政策相关条款的修改。如本政策的条款与平台采用的任何服务协议中规定的个人信息保护及授权条款存在不一致或冲突之处，应当以本政策的相关条款为准。
        <br />
        <span style="font-weight:bold">九、争议的解决</span>
        <br />
        您和我们就本隐私政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，双方同意应将争议提交至亚联融汇（北京）科技有限公司所在地人民法院诉讼解决。
        <br />
        <span style="font-weight:bold">十、如何联系我们</span>
        <br />
        如果您对本隐私政策有投诉、建议、疑问，您可以通过以下方式与我们联系：发送电子邮件至data@afca-t.com.
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.help-detail .helpTop h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: #1d202b;
    margin-bottom: 30px;
}
.help-detail .helpTop h3 span {
    width: 4px;
    height: 24px;
    background: #3d61ff;
    display: inline-block;
    margin-right: 10px;
}
.help-detail .helpTop {
    padding: 50px 0px 0;
    max-width: 840px;
    margin: 0 auto;
}
.help-detail .helpMain {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
}
.help-detail .helpMain  {
    font-size: 14px;
    font-weight: 400;
    color: #1d202b;
    line-height: 30px;
    min-height: 1150px;
}
</style>